import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Home from "./public/Home";
import Processso from "./public/Processso";
import Regularize from "./public/Regularize";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/processso" element={<Processso />} />
        <Route path="/regularize" element={<Regularize />} />

        {/* Rota para capturar páginas inexistentes e redirecionar */}
        <Route path="*" element={<Processso />} />
      </Routes>
    </Router>
  );
};

export default App;
