import React from "react";
import Bg from "./../assets/background.jpeg";
import { Link } from "react-router-dom";

const CarteiraDigitalTransito = () => {
  return (
    <div
      className="flex items-end min-h-screen bg-cover bg-top"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="bg-white w-full p-5 rounded-t-2xl shadow-lg text-center -mt-12">
        <h1 className="text-[#1351b4] font-semibold text-[25px] mt-10 mb-2">
          Bem-vindo à <strong>CDT</strong>
        </h1>
        <h2 className="text-[#1351b4] font-semibold text-[20px] mb-4">
          Carteira Digital de Trânsito
        </h2>
        <p className="text-gray-600 text-[15px] font-semibold  mt-10 ">
          Ao entrar, você concorda com nosso
        </p>

        <p className="text-gray-600 text-sm font-semibold mb-5">
          <a className="text-[#1351b4] underline  ">
            Termo de Responsabilidade
          </a>
          &nbsp;e&nbsp;
          <a className="text-[#1351b4] underline ">Política de Privacidade</a>
        </p>

        <Link
          to="/processso"
          className="inline-block w-full  bg-[#1351b4] mb-12 text-white px-10 py-2 rounded-full text-[18px] font-bold hover:bg-blue-800 transition"
        >
          ENTRAR COM gov.br
        </Link>
      </div>
    </div>
  );
};

export default CarteiraDigitalTransito;
